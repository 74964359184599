import {
  createTheme,
  genPageTheme,
  darkTheme,
  shapes,
} from '@backstage/theme';

const colorPair = ['#0D9E7A', '#05CC98']

export const paloItTheme = createTheme({
  palette: {
    ...darkTheme.palette,
    primary: {
      main: '#09CC98',
    },
    secondary: {
      main: '#FFAF3B',
    },
    error: {
      main: '#FC4F60',
    },
    warning: {
      main: '#Ef8035',
    },
    info: {
      main: '#34548a',
    },
    success: {
      main: '#38E680',
    },
    background: {
      default: '#333333',
      paper: '#444444',
    },
    banner: { // still don't know where it is
      info: '#09CC98',
      error: '#8c4351',
      text: '#343b58',
      link: '#565a6e',
    },
    errorBackground: '#8c4351',
    warningBackground: '#8f5e15',
    infoBackground: '#343b58',
    navigation: {
      background: '#444444',
      indicator: '#09CC98',
      color: '#d5d6db',
      selectedColor: '#ffffff',
    },
  },
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: colorPair, shape: shapes.wave }),
    documentation: genPageTheme({
      colors: colorPair,
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: colorPair, shape: shapes.round }),
    service: genPageTheme({
      colors: colorPair,
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: colorPair,
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: colorPair,
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: colorPair, shape: shapes.wave }),
    app: genPageTheme({ colors: colorPair, shape: shapes.wave }),
    apis: genPageTheme({ colors: colorPair, shape: shapes.wave }),
  },
});